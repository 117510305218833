@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    color: #001f33;
}
  
ul, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

.App {
    position: relative;
    width: 100%;
    height: 100%;
}



/* @media (min-width: 1250px) {
    .container {
        margin: 0 auto;
        width: 1248px;
    }
}

@media (min-width: 1250px) {
    .container {
        margin: 0 auto;
        max-width: 1248px;
    }
} */



@media (min-width: 374px) {
    .container {
        margin: 0 auto;
        padding: 0 16px;
        max-width: 100%;
        position: relative;
    }
}

@media (min-width: 1250px) {
    .container {
        margin: 0 auto;
        padding: 0 16px;
        max-width: 1248px;
        position: relative;
    }
}

/* @media (min-width: 376) {
    .container {
        margin: 0 auto;
        max-width: 355px;
    }
} */