.passwordColorSignIn {
    margin-top: 30px !important;
}




/* PART OF RESPONSIVE */
@media (min-width: 374px) {
    .containerSignIn {
        height: 700px !important;
    }
    .brandSignIn {
        height: 100px !important;
    }

}


@media (min-width: 1180px) {
    .containerSignIn {
        height: 100vh !important;
    }
    .brandSignIn {
        height: 100px !important;
    }

}