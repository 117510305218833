/* Set css main Navigation bar */
nav {
    width: 100%;
    height: 60px;
    /* box-shadow: 0 1px 2px rgb(0 0 0 / 10%); */
    box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 10%), 0px 1px 3px 1px rgb(60 64 67 / 10%);
    background-color: white;
    position: fixed;
    z-index: 99;
    
}

.navbar {
    height: inherit;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: relative;
    /* background-color: white; */
}


/* Set css Navigation Brand */
.navBrand {
    display: flex;
    justify-items: flex-start;
    align-items: center;
}

.brandLogo {
    width: 30px;
    height: 30px;
}

.brandLogo img {
    width: 100%;
    /* animation: rotation 5s infinite ease;
    animation: name duration timing-function delay iteration-count direction fill-mode; */
    animation-name: rotation;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-delay: 5s;
    animation-iteration-count: infinite;

}

@keyframes rotation {
    0% {
        animation-delay: 2s;
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(45deg);
    }
    66% {
        transform: rotate(-45deg);
    }
    99% {
        transform: rotate(0deg);
    }
}

.brandName {
    font-size: 30px;
    font-weight: 700;
    margin-left: 8px;
    color: #14142f;
}


/* Set css Navigation Control */
.navControl {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    place-items: center;
    place-content: center;
    background-color: white;
}

.navConIt {
    /* width: calc(30% - 48px); */
    height: 50px;
    margin: 0 4px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    place-items: center;
    place-content: center;
    position: relative;
    border-radius: 8px;
    transition: all 0.15s ease-in-out;
    color: #14142f;
}

.navConIt i {
    font-size: 24px;
}

.navConIt:hover {
    background-color: #f2f3f3;
}



/* Set css Navigation Login and Logout */
.navLog {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    position: relative;
}
.logCon {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    cursor: pointer;
    /* width: 130px; */
    padding: 4px;
    border-radius: 16px;
}

.logCon:hover {
    background-color: #f2f3f3;
}

.pfIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: 100%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    
}

/* .pfIcon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
} */

.navLog #name {
    font-weight: 600;
    margin: 0 8px;
}

.btnLogout button {
    /* display: none; */
    position: absolute;
    /* width: 94px;
    height: 40px; */
    padding: 6px 16px;
    top: 10px;
    right: 0px;
    /* background: #65676B; */
    /* border-color: #F44336; */
    border-color: #65676B;
    /* border: none; */
    border-radius: 8px;
    /* color: #F44336; */
    color: #14142f;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    opacity: 0;
}

.btnLogout button i {
    padding: 4px;
}

.logCon:hover .btnLogout button {
    display: block;
    opacity: 1;
}



/* Is Active Menu button */
.isSelected {
    background-color: #14142f;
}
.isSelected i{
    color: white;
}
.isSelected:hover {
    background-color: #14142f;
}






.logOut {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    /* place-items: center; */
}




.btnLogout {
    width: 300px;
    /* height: 100px; */
    background-color: white;
    /* box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    border: 1px solid rgb(210, 210, 210); */
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 4px;
    position: absolute;
    top: 50px;
    /* right: -2px; */
    padding: 4px 4px;
    display: none;
}





.pfLogOutMain {
    width: 100%;
    /* height: 72px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 4px;
    border-radius: 4px;
    
}
.pfLogOutMain:hover {
    cursor: not-allowed;
    background: rgb(210, 210, 210);
}


.pfIconLogOut {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid white;
    background-size: 100%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    
}

.pfIconLogOut img {
    width: 100%;
}



.setpfLogOutName {
    font-size: 18px;
    font-weight: 700;
    margin-left: 12px;
}

.pfLogOutName span {
    display: block
}

.pfLogOutNameDes {
    font-size: 14px;
    font-weight: 500;
    margin-left: 12px;
}



.pfLogOutMainHr {
    width: 95%;
    margin: 4px auto;
    height: 1px;
    background: rgb(210, 210, 210);
}

.btnLogoutLogout {
    width: 100%;
    /* height: 72px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
}
.btnLogoutLogout:hover {
    background: rgb(210, 210, 210);
}


.btnLogoutLogoutLogout {
    margin-left: 8px;
}














/* TODO: RESPONSIVE NAVIGATION BAR */
@media (min-width: 374px) {
    .navbar {
        width: inherit;
        height: inherit;
        grid-template-columns: 1fr 1fr;
        /* background-color: white; */
    }

    .navControl {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 60px;
        border-radius: 8px 8px 0px 0px;
    }

    .navConIt {
        width: 33%;
    }
}

@media (min-width: 1180px) {
    .navbar {
        height: inherit;  
        grid-template-columns: 1fr 2fr 1fr;   
        /* background-color: white; */
    }

    .navControl {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        place-items: center;
        place-content: center;

        position: inherit;
        left: inherit;
        bottom: inherit;
        height: inherit;
    }

    .navConIt {
        width: calc(30% - 48px);
    }
    
}









