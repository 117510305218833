@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
.badgesSec {
    background-color: white;
    position: relative;
    z-index: 1;
}

.badgesMain {
    /* padding-top: 100px; */
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: #f2f3f3; */
    
}



.headerBadges {
    padding-top: 80px; 
    padding-bottom: 10px;
    position: relative;
}

.headerBadges span:first-child {
    font-size: 22px;
    font-weight: 700;
    /* padding-left: 25px; */
    position: relative;
}


.hrHeader {
    width: 100%;
    height: 10px;
    border-bottom: 1px solid rgba(175,175,182,0.4);
    margin-bottom: 16px;
}


.certiMain {
    width: 250px;
    height: 170px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    /* place-items: center; */
    /* padding: 4px; */
    /* box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06); */
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    transition: all 0.15s ease-in-out;
    transform: rotate(0deg);
}
.certiMain:hover {
    transform: scale(1.05);
}

.certiMain:hover .certiLogo img {
    /* width: 100%; */
    animation: rotation2 1s 1 ease;
}

@keyframes rotation2 {
    0% {
        transform: rotate(0deg) scale(1);
    }
    33% {
        transform: rotate(20deg) scale(1.05);
    }
    66% {
        transform: rotate(-20deg) scale(1.05);
    }
    99% {
        transform: rotate(0deg) scale(1);
    }

}



.certiSub {
    position: absolute;
    width: 100%;
    height: 110px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 20;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
    /* border-radius: 8px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px; */
}

.certi {
    width: 100%;
    /* border-radius: 4px; */
    position: absolute;
    filter: blur(2px);
    z-index: 10;
}

.certiLogo {
    width: 50px;
    height: 50px;
    z-index: 20;
    position: absolute;
    top: -30px;
    right: 20px;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    place-content: center;
    place-items: center;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

.certiLogo img {
    height: 90%;
}



.certiName {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    justify-self: start;
    z-index: 20;
    position: relative;
    top: 5px;
}


.certiDes {
    font-weight: 300;
    font-size: 12px;
    z-index: 20;
    position: relative;
    left: 10px;
}
.certiDes b {
    color: black;
    font-size: 12px;
    font-weight: 600;
}



.doneMs {
    width: 100%;
    display: flex;
    place-content: center;
    font-weight: 700;
}






/* -> Change color Progress bar */
.progressCertiContainer {
    height: 4px;
    padding: 0 10px;
}

.progressCerti {
    /* position: absolute; */
    height: 100% !important;
    margin-top: 8px;
    background-color: #f2f3f3 !important;
    z-index: 20;
}
.progressCerti span {
    height: 100% !important;
    background-image: radial-gradient( circle 976px at 51.2% 51%,  rgba(11,27,103,1) 0%, rgba(16,66,157,1) 0%, rgba(11,27,103,1) 17.3%, rgba(11,27,103,1) 58.8%, rgba(11,27,103,1) 71.4%, rgba(16,66,157,1) 100.2%, rgba(187,187,187,1) 100.2% );
}


/* -> Download menu */
.iconDownloadAllowed {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    padding-top: 14px;
    padding-right: 16px;
    position: relative;
    /* bottom: 4px; */
    /* right: 6px; */
}
.iconDownloadAllowed i {
    cursor: pointer;
}




#showTotal b {
    color: black;
}

.Certificates {
    position: relative;
}


.filterGrey {
    width: 100%;
    height: 100%;
    background-color: rgba(242, 243, 243,0.7);
    position: relative;
    z-index: 50;
}
.filterGreyMain img {
    filter: grayscale(1);
}

.doneMs2 {
    font-weight: 500;
    text-align: center;
    position: relative;
    top: 8px;
    left: 98px;
}




/* PART OF RESPONSIVE */
@media (min-width: 374px) {
    #showTotal {
        position: absolute;
        right: 20px;
        top: 90px;
    }

}


@media (min-width: 1180px) {
    #showTotal {
        position: absolute;
        right: 120px;
        top: 90px;
    }
}