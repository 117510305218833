.greetingMain {
    padding-top: 60px;
}

.viblyMain {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    /* place-content: center; */
    /* place-items: center; */
}

.desMain {
    width: calc(100% - 300px);

}

/* .content-about-vibly {
    padding-left: 50px;
    margin: 0 auto;
    position: relative;
    height: 100vh;
} */

.viblyHeader {
    padding-top: 180px;
    font-size: 10rem;
}

.pomo {
    margin-top: -20px;
    font-size: 3rem;
    margin-bottom: 5px;
}

.pomoDes p,
.pomoDes span a {
    line-height: 25px;
    width: 600px;
    letter-spacing: 0px;
    font-weight: 200;
    font-size: 1.2rem;
}

.img {
    /* position: absolute; */
    width: 300px;
    height: 300px;
    display: flex;
    align-self: center;
    /* top: 200px; */
    /* right: 30px; */
    /* overflow: hidden; */
    /* animation: clock 3s infinite ease-out; */
    position: relative;
}

.img img {
    position: absolute;
    transition: all 0.15s ease-in-out;
}

.pomoDes a {

    color: #5472ff;
}

.img img:nth-child(1) {
    animation: clock 3s 1.33s infinite ease-in-out;
    top: -40px;
}

.img img:nth-child(2) {
    animation: clock 3s 1s infinite ease-in-out;
    top: -40px;
}

.img img:nth-child(3) {
    animation: clock 3s 1.66s infinite ease-in-out;
    top: -40px;

}


@keyframes clock {
    0% {
        animation-delay: 2s;
        transform: translateY(0px);
    }

    33% {
        transform: translateY(40px);
    }

    66% {
        transform: translateY(40px);
    }

    99% {
        transform: translateY(0px);
    }


}




/* TODO: RESPONSIVE NAVIGATION BAR */
@media (min-width: 374px) {
    .greetingMain {
        padding-top: 40px;
        /* height: 100vh */
    }

    .viblyMain {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        /* place-content: center; */
        /* place-items: center; */
    }

    .desMain {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

    /* .content-about-vibly {
        padding-left: 50px;
        margin: 0 auto;
        position: relative;
        height: 100vh;
    } */

    .viblyHeader {
        padding-top: 60px;
        font-size: 4rem;
    }

    .pomo {
        margin-top: -20px;
        font-size: 2rem;
        margin-bottom: 5px;
    }

    .pomoDes p,
    .pomoDes span a {
        line-height: 25px;
        width: 600px;
        letter-spacing: 0px;
        font-weight: 200;
        font-size: 1.2rem;
    }

    .img {
        /* position: absolute; */
        width: 300px;
        height: 300px;
        display: flex;
        align-self: center;
        /* top: 200px; */
        /* right: 30px; */
        /* overflow: hidden; */
        /* animation: clock 3s infinite ease-out; */
        position: relative;
        display: none;
    }

    .img img {
        position: absolute;
        transition: all 0.15s ease-in-out;
        
    }

    .pomoDes a {

        color: #5472ff;
    }

    .img img:nth-child(1) {
        animation: clock 3s 1.33s infinite ease-in-out;
        top: -40px;
    }

    .img img:nth-child(2) {
        animation: clock 3s 1s infinite ease-in-out;
        top: -40px;
    }

    .img img:nth-child(3) {
        animation: clock 3s 1.66s infinite ease-in-out;
        top: -40px;

    }
}

@media (min-width: 1180px) {

    .greetingMain {
        padding-top: 60px;
    }

    .viblyMain {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        /* place-content: center; */
        /* place-items: center; */
    }

    .desMain {
        width: calc(100% - 300px);
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;

    }

    /* .content-about-vibly {
        padding-left: 50px;
        margin: 0 auto;
        position: relative;
        height: 100vh;
    } */

    .viblyHeader {
        padding-top: 180px;
        font-size: 10rem;
    }

    .pomo {
        margin-top: -20px;
        font-size: 3rem;
        margin-bottom: 5px;
    }

    .pomoDes p,
    .pomoDes span a {
        line-height: 25px;
        width: 600px;
        letter-spacing: 0px;
        font-weight: 200;
        font-size: 1.2rem;
    }

    .img {
        /* position: absolute; */
        width: 300px;
        height: 300px;
        display: flex;
        align-self: center;
        /* top: 200px; */
        /* right: 30px; */
        /* overflow: hidden; */
        /* animation: clock 3s infinite ease-out; */
        position: relative;
    }

    .img img {
        position: absolute;
        transition: all 0.15s ease-in-out;
    }

    .pomoDes a {

        color: #5472ff;
    }

    .img img:nth-child(1) {
        animation: clock 3s 1.33s infinite ease-in-out;
        top: -40px;
    }

    .img img:nth-child(2) {
        animation: clock 3s 1s infinite ease-in-out;
        top: -40px;
    }

    .img img:nth-child(3) {
        animation: clock 3s 1.66s infinite ease-in-out;
        top: -40px;

    }

}