footer {
    /* width: 100%; */
    background-color: #14142f;
    color: white;
    margin: 0;
    padding: 0 16px;
}

.footer {
    padding-top: 100px;
    padding-bottom: 100px;
}


/* Project Name */
.projectName {
    width: 100%;
    color: white;
    position: relative;
    margin-bottom: 30px;
}
.projectName a, .projectName span, ul, li, b {
    color: white;
}

.projectName a {
    display: block;
}
.projectName a img {
    width: 25px;
    position: relative;
    top: 3px;
}
.projectName a span {
    font-size: 24px;
    font-weight: 700;
    margin-left: 4px;
}
.desVibly {
    display: block;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #B0B0B7;
}









/* Set hr */
.hr {
    border-bottom: 1px solid rgba(175,175,182,0.5);
    margin: 32px 0;
}



/* Set footer Navigation */
.footerNav {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footerNav li a {
    font-weight: 400;
    color: #B0B0B7;
    font-size: 16px;
}
.footerNav ul li {
    margin: 8px 0;
}

.footerNav ul li:first-child {
    font-weight: 600;
}


/*  Set Copyright */

.developBy2 {
    position: absolute;
    bottom: 20px;
    font-weight: 600;
    right: 18px;
    z-index: 40;
    opacity: 1;
    color: white;
}
.developBy2 span,.developBy2 i {
    color: white;
}
.developBy2 .fa-code {
    position: relative;
    top: 1px;
}


/* TODO: RESPONSIVE FOOTER */



/* PART OF RESPONSIVE */
@media (min-width: 374px) {
    .footer {
        padding-top: 100px;
        padding-bottom: 200px;
    }
    .developBy2 {
        color: white;
        font-weight: 600;
        position: absolute;
        bottom: 130px;
        right: 24px;
        z-index: 40;
        opacity: 1;
    }
    

}


@media (min-width: 1180px) {
    .footer {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .developBy2 {
        position: absolute;
        bottom: 20px;
        font-weight: 600;
        right: 18px;
        z-index: 40;
        opacity: 1;
        color: white;
    }
    
}