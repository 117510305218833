/* Import Font */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



/* Set Section */
.focusSec {
    height: 100vh;
    background-color: #f2f3f3;
    /* background-color: black; */
    position: relative;
    z-index: 10;
}



/* Set Progress Bar */
.ProgressBar {
    position: fixed !important;
    top: 60px;
    z-index: 100;
}

.cxLOPe,
.ALByV {
    background-color: #f2f3f3 !important;
}

.lmcRcU {
    background-color: #f85428 !important;
}



.focusMain {
    /* padding-top: 60px; */
    height: 100%;
    display: flex;
    place-content: center;
    place-items: center;

}



.focusSub {
    max-width: 600px;
    min-height: 400px;
    background-color: white;
    border-radius: 10px;
    /* box-shadow:
        0px 0px 0.8px rgba(0, 0, 0, 0.022),
        0px 0px 2px rgba(0, 0, 0, 0.031),
        0px 0px 4.1px rgba(0, 0, 0, 0.039),
        0px 0px 8.4px rgba(0, 0, 0, 0.048),
        0px 0px 23px rgba(0, 0, 0, 0.07); */
    /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
    box-shadow: rgba(0, 31, 51, 0.4) 0px 0px 0px 2px, rgba(0, 31, 51, 0.65) 0px 4px 6px -1px, rgba(0, 31, 51, 0.08) 0px 1px 0px inset;
    position: relative;
    overflow: hidden;
    z-index: 0;
}



.controlMode {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    place-items: center;
    /* column-gap: 10px; */
    /* padding: 0 4px; */
}

.controlModeIt {
    width: 100%;
    height: 45px;
    /* background-color: #6f6f6f; */
    display: flex;
    place-content: center;
    place-items: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    box-shadow: inset 2px -2px 2px -2px rgba(0, 0, 0, 0.1);
}

.controlModeIt span {
    font-weight: 400;
    color: black;
    transition: all 0.15s ease-in-out;
    position: relative;
    z-index: 20;
}

.controlModeIt:hover span {
    letter-spacing: 1.5px;
}




.modeIsActive {
    box-shadow: none;
    /* color: white; */
    /* background-color: white; */
}

.modeIsActive span {
    color: black;
    font-weight: 700;
}

.pomord-8 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 16px;
}

.custrd-8 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 4px;
    background-color: #EEEEEE;
}





.progMain {
    width: 100%;
    height: 30px;
    margin-top: 16px;
    display: flex;
    place-content: center;
    place-items: center;
}

.progMain i {
    width: 30px;
    height: 30px;
    display: flex;
    place-content: center;
    place-items: center;
    flex-wrap: nowrap;
    text-transform: uppercase;
    color: #f2f3f3;
    position: relative;
}

.progMain span {
    width: 120px;
    height: 30px;
    display: flex;
    place-content: center;
    place-items: center;
    flex-wrap: nowrap;
    text-transform: uppercase;
    border-radius: 4px;
}

.progressNavActive {
    color: #c8c8c8 !important;
    animation-name: slide-right;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    animation-delay: NaNs;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.progressNavActiveReverse {
    color: #c8c8c8 !important;
    animation-name: slide-left;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
    animation-delay: NaNs;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

}

@keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(0px);
    }

    55% {
        opacity: 1;
        transform: translateX(20px);
    }

    60% {
        opacity: 1;
        transform: translateX(20px);
    }

    65% {
        opacity: 0.5;
        transform: translateX(22px);
    }

    70% {
        opacity: 0.2;
        transform: translateX(24px);
    }

    80% {
        opacity: 0;
        transform: translateX(25px);
    }

    100% {
        opacity: 0;
        transform: translateX(0px);
    }
}

@keyframes slide-left {
    0% {
        opacity: 0;
        transform: translateX(-4px);
    }

    55% {
        opacity: 1;
        transform: translateX(-16px);
    }

    60% {
        opacity: 1;
        transform: translateX(-16px);
    }

    65% {
        opacity: 0.5;
        transform: translateX(-18px);
    }

    70% {
        opacity: 0.2;
        transform: translateX(-20px);
    }

    80% {
        opacity: 0;
        transform: translateX(-21px);
    }

    100% {
        opacity: 0;
        transform: translateX(-4px);
    }
}

.progItem {
    /* padding: 30px 60px; */
    font-weight: 300;
    /* background-color: white; */
}

.progActive {
    font-weight: 700;
    background-color: #f85428;
    color: white;
    position: relative;
    top: -2px;
    -webkit-animation: shadow-pop-tr .3s cubic-bezier(.47, 0.000, .745, .715) both;
    animation: shadow-pop-tr .3s cubic-bezier(.47, 0.000, .745, .715) both
}

@keyframes shadow-pop-tr {
    0% {
        -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }

    100% {

        box-shadow:
            1px -1px #6f6f6f,
            2px -2px #6f6f6f,
            3px -3px #6f6f6f;
        transform: translateX(-3px) translateY(3px)
    }
}

.ontask {
    background-color: #f85428 !important;
}

.rest {
    background-color: #4C83FF !important;
}

.done {
    background-color: #28C76F !important;
}

.ontask2 span {
    background-color: #f85428 !important;
}

.rest2 span {
    background-color: #4C83FF !important;
}

.done2 span {
    background-color: #28C76F !important;
}

.disableBtn {
    cursor: not-allowed;
}




.timer {
    background-color: white;
    height: 235px;
    display: grid;
    grid-template-columns: 10fr 1fr 10fr;
    position: relative;
}

.timerIt {
    font-family: 'Nunito', sans-serif;
    font-size: 10rem;
    font-weight: 700;
    align-self: center;
    position: inherit;
    text-shadow: 2px 2px 1px rgb(248, 84, 40);
}

.timerIt:nth-child(1) {
    justify-self: end;
}

.timerIt:nth-child(2) {
    justify-self: center;
    top: -15px;
    margin: 0 12px;
    font-size: 7rem
}

.timerIt:nth-child(3) {
    justify-self: start;
}



.controlBtn {
    /* width: 70px; */
    padding: 4px 16px;
    margin: 4px;
    height: 45px;
    display: flex;
    place-content: center;
    place-items: center;
}

.controlBtn button {
    border: none;
    padding: 4px 16px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
}

.btnChooseTask {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 30px;
}

button:disabled {
    background-color: white;
    /* color: rgb(205, 205, 205); */
    color: black;
    cursor: not-allowed;
}

.button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #001f32;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 4px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: 600 !important;
}

.button-85:before {
    content: "";
    background: linear-gradient(45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000);
    position: absolute;
    top: -1px;
    left: -1px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.button-85:after {
    content: "";
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

/* 

.scroll-downs {
    position: absolute;
    top: -600px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 34px;
    height: 55px;
    z-index: 10;
}

.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #001f33;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #001f33;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    animation-iteration-count: infinite;
} */


@keyframes scroll {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}




































/* PART OF RESPONSIVE */
@media (min-width: 374px) {
    .focusMain {
        padding-top: 0;
    }

    .focusSub {
        max-width: 350px;
    }

    .timerIt {
        font-size: 7rem;
    }

    .progMain {
        padding: 0 8px;
    }

    @keyframes shadow-pop-tr {
        0% {
            -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0)
        }

        100% {

            box-shadow:
                1px -1px #6f6f6f;

            transform: translateX(-1px) translateY(1px)
        }
    }
    .btnChooseTask {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 30px;
    }
}


@media (min-width: 1180px) {
    .focusMain {
        padding-top: 60px;
    }

    .focusSub {
        max-width: 600px;
        /* background: #EEEEEE; */
    }

    .timerIt {
        font-size: 10rem;
    }

    @keyframes shadow-pop-tr {
        0% {
            -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0)
        }

        100% {
            box-shadow:
                1px -1px #6f6f6f,
                2px -2px #6f6f6f,
                3px -3px #6f6f6f;
            transform: translateX(-3px) translateY(3px)
        }
    }
}