.soundSec {
    background-color: white;
    padding-top: 50px;
}




.soundMain {
    padding-bottom: 100px;
    position: relative;
    z-index: 50;
}

.headerSound span:first-child {
    font-size: 26px;
    font-weight: 600;
    position: relative;
}


.soundPath {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: center;

    
}

.soundPath li {
    width: 140px;
    height: 140px;
    border-radius: 4px;
    /* background-color: #EEEEEE; */
    margin: 15px 15px;
    /* box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06); */
    cursor: pointer;
    /* background-size: cover;  */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    position: relative;
}

.soundPath li:hover div {
    background-size: 70%;
}


.soundBg {
    width: 100%;
    height: 100%;

}


.bgSound {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
}



.activeSound {
    /* padding: 0.6em 2em; */
    border: none;
    outline: none;
    /* color: rgb(255, 255, 255); */
    /* background: #001f32; */
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 4px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: 600 !important;
}

.activeSound:before {
    content: "";
    background: linear-gradient(45deg,
        #ef3550,
        #f48fb1,
        #7e57c2,
        #2196f3,
        #26c6da,
        #43a047,
        #eeff41,
        #f9a825,
        #ff5722);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}
@keyframes glowing-button-85 {
    0% {background-position: 0 0;}
    50% {background-position: 400% 0;}
    100% {background-position: 0 0;}
}
.activeSound:after {
    content: "";
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

