/* section {
    background-color: #f2f3f3;
    position: relative;
    z-index: 10;
} */



.communityMain {
    /* padding-top: 100px; */
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.headerCommunity {
    padding-top: 80px;
    padding-bottom: 10px;
}

.headerCommunity span:first-child {
    font-size: 22px;
    font-weight: 700;
    position: relative;
}


.person1 {
    margin: 15px 15px;
    width: 200px;
    height: 250px;
    border-radius: 4px;
    background-color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
    z-index: 1;
    transition: all 0.15s ease-in-out;
}

.person1:hover {
    transform: scale(1.05);
}


.backgroundFaculty {
    /* 190 */
    height: 60px; 
    width: 100%;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.backgroundFaculty img {
    width: 100%;
    position: absolute;
    top: -10px;
}

.profileImage {
    width: 60px;
    height: 60px;
    z-index: 10;
    top: 30px;
    position: absolute;
    
}

.bgProfile {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    z-index: 20;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: solid 5px white;
}


.name {
    /* 120 */
    height: 50px ;
    padding-top: 30px ;
    font-size: 16px ;
    font-weight: 500;
}

.faculty {
    /* 95 */
    height: 20px ;
    font-size: 0.6rem ;
    font-weight: 300 ;
    margin-bottom: 5px ;
    color: #14142f ;
    position: relative;
    top: -8px;

}

.faculty span {
    font-size: 0.6rem ;
    font-weight: 300 ;
}


.badges {
    /* 70 */
    height: 30px ;
    padding-top: 0px ;
    display: flex ;
    /* grid-template-columns: repeat(2, 1fr); */
}

.imgIcon {
    width: 20px;
    height: 20px;
    margin: 0 5px;
}

.imgIcon img {
    width: 100%;
}



.status {
    /* 30 */
    height: 50px;
    width: 100%;
    /* padding-top: 20px; */
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
}

.statusHours {
    font-size: 0.8rem;
    font-weight: 600;
    width: 100%;
    display: block;
    text-align: center;
    
}
.statusDes {
    width: 100%;
    display: block;
    font-size: 0.6rem;
    color: #adafca;
    text-align: center;
}


.socialLink {
    width: 100%;
    height: 35px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
}

.socialLink a {
    margin: 0 10px;
}

.socialLink i {
    color: #14142f;
    transition: all 0.15s ease-in-out;
}

.socialLink i:hover {
    transform: scale(1.1)
}




.socialLinkNotAllowed {
    cursor: no-drop;
    color: #f2f3f3 !important;
}
.socialLinkNotAllowed:hover {
    transform: scale(1) !important;
}




#person11 {
    background-color: red;
}