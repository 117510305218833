
.filterSignUp {
    width: 100%;
    height: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    place-content: center;
    place-items: center;
    background-color: rgba(20, 20, 47, 0.8);
    z-index: 10;
}
.backgroundSignUp {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    place-content: center;
    place-items: center;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    z-index: 0;
}
.containerSignUp {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}



/* STUB Brand component */
.brandSignUp {
    position: relative;
    z-index: 30;
    /* top: -50px; */
    display: flex;
    place-content: center;
    place-items: center;
}
.brandNamesignUp {
    margin-left: 110px;
    font-size: 80px;
    font-weight: 700;
    color: white;
    position: relative;
    top: -36px;
    left: 0px;
}
.textRainbow {
    text-shadow: -4px 4px #ef3550,
    -8px 8px #f48fb1,
    -12px 12px #7e57c2,
    -16px 16px #2196f3,
    -20px 20px #26c6da,
    -24px 24px #43a047,
    -28px 28px #eeff41,
    -32px 32px #f9a825,
    -36px 36px #ff5722;
}
.brandDesSignUp {
    display: block;
    color: white;
    position: relative;
    top: 50px;
    left: -220px;
    font-weight: 500;
    font-size: 16px;
}



/* STUB form sing up component */
.formMain {
    width: 510px;
    padding: 40px 56px;
    border-radius: 4px;
    background: white;
    position: relative;
    z-index: 30;
}
.formMain span,
.formMain a {
    font-weight: 500;
    font-size: 12px;
}
.titleSignUp {
    font-size: 36px;
}
.linkSignUp {
    color: #1473e6 !important;
}
.formContainerSignUp {
    margin-top: 50px;
}
.formContainerSignUp .txtFieldSignUp {
    position: relative;
    border-bottom: 2px solid #f2f2f3;
    margin: 15px 0;
}
.txtFieldSignUp input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
}
.txtFieldSignUp label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .5s;
    color: #adadad;
    font-size: 14px;
    font-weight: 500;
}
.txtFieldSignUp span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    /* background: #e27100; */
    transition: .5s;
}
.txtFieldSignUp input:focus~label,
.txtFieldSignUp input:valid~label {
    top: -5px;
    color: #ce0c0c;
}
.txtFieldSignUp input:focus~span::before,
.txtFieldSignUp input:valid~span::before {
    width: 100%;
}



.emailColorSignUp input:focus~label,
.emailColorSignUp input:valid~label {
    top: -5px;
    color: #ef3550 ;
}
.emailColorSignUp {
    color: #ef3550 ;
}
.emailColorSignUp::before {
    background-color: #ef3550 ;
}



.nameAndLastName {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 50px;
    /* height: 100px; */
}
.nameInput {
    width: 45%;
}
.nameInput input {
    height: 40px;
    width: 100%;
    padding: 0 5px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
    font-weight: 700;
}
.nameInput span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #2691d9;
    transition: .5s;
}



.nameColorSignUp input:focus~label,
.nameColorSignUp input:valid~label {
    top: -5px;
    color: #f48fb1 ;
}
.nameColorSignUp {
    color: #f48fb1 ;
}
.nameColorSignUp::before {
    background-color: #f48fb1 ;
}
.nameColorSignUp span::before {
    background: #f48fb1 ;
}



.noticeAboutName {
    font-size: 12px !important;
    position: absolute;
    padding-left: 5px;
    margin-right: 40px;
    top: 280px;
    color: #a6a6a6;
}



/* TODO: set color */
.lastNameColorSignUp input:focus~label,
.lastNameColorSignUp input:valid~label {
    top: -5px;
    color: #7e57c2;
}
.lastNameColorSignUp {
    color: #7e57c2;
}
.lastNameColorSignUp::before {
    background-color: #7e57c2;
}
.lastNameColorSignUp span::before {
    background: #7e57c2;
}



/* TODO: set color */
.passwordColorSignUp input:focus~label,
.passwordColorSignUp input:valid~label {
    top: -5px;
    color: #2196f3;
}
.passwordColorSignUp {
    color: #2196f3;
    /* margin-top: 30px !important;; */
}
.passwordColorSignUp::before {
    background-color: #2196f3;
}
.showPassword {
    position: absolute;
    right: 10px;
    top: 16px;
    cursor: pointer;
}
.showPassword i {
    color: #adadad;
}
.fa-eye {
    color: #2196f3 !important;
}



.dropdownFieldSignUp {
    width: 100%;
    height: 55px;
    display: flex;
    place-content: center;
    place-items: center;
}
.dropdownFieldSignUp label {
    margin-left: 6px;
    font-size: 14px;
    color: #43a047;
    font-weight: 500;
}
.dropdownFieldSignUp dropdown_field {
    font-size: 16px;
    font-weight: 500;
}
.dropdownSelectSignUp {
    margin-left: 16px;
    width: calc(100% - 42px);
    border: 1px solid #43a047;
    border-radius: 4px;
}
.dropdownSelectSignUp:valid {
    color: #43a047;
    font-weight: 700;
}



/* TODO: set color */
.instagramIconSignUp i {
    color: #adadad;
    margin-right: 5px;
    position: relative;
    top: 1px;
    transition: .5s;
}
.instagramColorSignUp input:focus~label,
.instagramColorSignUp input:valid~label {
    top: -5px;
    color: #f9a825;
}
.instagramColorSignUp {
    color: #f9a825;
}
.instagramColorSignUp::before {
    background-color: #f9a825;
}
.instagramColorSignUp input:focus~.instagramIconSignUp i,
.instagramColorSignUp input:valid~.instagramIconSignUp i {
    color: #f9a825;
}





.fileFieldSignUp {
    width: 100;
    height: 70px;
    display: flex;
    justify-content: center; 
    flex-direction: column
}

.fileFieldSignUp label {
    margin-left: 6px;
    margin-top: 20px;
    /* margin-bottom: 10px; */
    font-size: 14px;
    font-weight: 500;
    color: #adadad;
}
.fileFieldSignUp label i {
    color: #adadad;
    font-size: 14px;
}
.wrapper{
    /* width:100%; */
    /* height:100%; */
    display:flex;
    align-items:center;
    justify-content:center;
}
.file-upload {
    height:70px;
    width:70px;
    border-radius: 100px;
    color: #FFFFFF;
    border: 2px solid #EEEEEE;
    overflow:hidden;
    background-image: linear-gradient(to bottom, #ffffff 50%, #eeff41 50%);
    background-size: 100% 200%;
    /* transition: all 1s; */
    position:relative;
    display:flex;
    justify-content:center;
    align-items: center;  
    transition: all 0.5s ease-in-out;
    font-size:100px;
    cursor: pointer;
}
.file-upload i {
    cursor: pointer;
    color: #c4c4c4;
}

#userProfile {
    height:100px;
    width:100px;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    cursor:pointer;
}
.file-upload:hover {
    background-position: 0 -100%;
    cursor: pointer;
    color:#2590EB;
    transform: scale(1.05);
}

.fileSelected {
    background-image: linear-gradient(to bottom, #eeff41 50%, #eeff41 50%) !important;
}














.btnSubmitSignUp {
    border: none;
    outline: none;
    font-weight: 500;
    background: none;
    background-color: #14142f;
    padding: 6px 18px;
    border-radius: 16px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    line-height: 1.3;
}
.btnSubmitSignUp:hover {
    background-color: #2b2b4b;
}
.submitBtn {
    margin-top: 50px;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
}
.fa-spin {
    color: white;
    margin-right: 4px;
    margin-left: -2px;
    position: relative;
    top: 0.5px
}



/* STUB develop by */
.developBy {
    position: absolute;
    bottom: 20px;
    font-weight: 600;
    left: 0px;
    z-index: 40;
    opacity: 0.5;
    color: white;
}
.developBy span,.developBy i {
    color: white;
}
.developBy .fa-code {
    position: relative;
    top: 1px;
}
.developByAnimateCharcter{
    /* text-transform: uppercase; */
    /* background-color: #0093E9; */
    background: linear-gradient(
        90deg,
        #ef3550 0%,
        #f48fb1 14.28%,
        #7e57c2 28.57%,
        #2196f3 42.85%,
        #43a047 57.14%,
        #eeff41 71.42%,
        #f9a825 85.71%,
        #ff5722 100%
        );
        background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 4s ease infinite;
    display: inline-block;    
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}



/* PART OF RESPONSIVE */
@media (min-width: 374px) {
    .brandDesSignUp {
        display: none;
        color: white;
        position: relative;
        top: 50px;
        left: -220px;
        font-weight: 500;
        font-size: 16px;
    }   
    .brandNamesignUp {
        margin-left: 145px;
        width: 100%;
        font-size: 80px;
        font-weight: 700;
        color: white;
        position: relative;
        top: -30px;
        left: 0px;
    }
    .formMain {
        width: 100%;
        padding: 40px 56px;
        border-radius: 4px;
        background: white;
        position: relative;
        top: -50px;
        z-index: 30;
    }

    .developBy {
        position: absolute;
        bottom: -36px;
        font-weight: 600;
        left: 85px;
        z-index: 40;
        opacity: 0.5;
        color: white;
    }
    .formMainSignUp {
        /* top: 50px !important; */
    }
    .containerSignUp {
        width: 100%;
        height: 1000px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;
    }
    .brandSignUp {
        position: relative;
        z-index: 30;
        height: 300px;

        /* top: -50px; */
        display: flex;
        place-content: center;
        place-items: center;
    }

    
    

}


@media (min-width: 1180px) {
    .brandDesSignUp {
        display: block;
        
        color: white;
        position: relative;
        top: 50px;
        left: -220px;
        font-weight: 500;
        font-size: 16px;
    }
    .brandNamesignUp {
        margin-left: 110px;
        font-size: 80px;
        font-weight: 700;
        color: white;
        position: relative;
        top: -36px;
        left: 0px;
    }
    .formMain {
        width: 510px;
        height: auto;
        padding: 40px 56px;
        border-radius: 4px;
        background: white;
        position: relative;
        top: auto;
        z-index: 30;
    }
    .developBy {
        position: absolute;
        bottom: 20px;
        font-weight: 600;
        left: 0px;
        z-index: 40;
        opacity: 0.5;
        color: white;
    }
    .containerSignUp {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: auto;
    }
    .brandSignUp {
        position: relative;
        height: auto;
        z-index: 30;
        /* top: -50px; */
        display: flex;
        place-content: center;
        place-items: center;
    }
    

}